<template>
    <div class="box_1">
      <div class="form_login" :disabled="codeValid">
          <div style="display: flex; justify-content: flex-start; align-content: center; align-items: center;" >
              <img src="@/assets/icons/retextoar-app.png" width="35px" height="35px"/>
              <p class="titleStyle">Retextoar</p>
          </div>
          <PasswordVerificationForm :redirectLoginPage="true" :showTextVersion="true" :userLogin="login"></PasswordVerificationForm>
       </div>
      <Carousel></Carousel>
    </div>
</template>

<script>
  import { userService } from '../services/user_service.js';
  import PasswordVerificationForm from './PasswordVerificationForm.vue';
  import Carousel from './Login/Carousel.vue';

  export default {
    components: {
      PasswordVerificationForm,
      Carousel
    },
    data () {
      return {
        codeValid: true,
        isLoading: false,
        fullPage: true,
        login: this.$route.query.email,
        submitted: false
      };
    },
    async created () {
      if (this.$route.query.redeem) {
        const result = await userService.redeemCode(this.$route.query.redeem);
        if (result.isCodeValid) {
          this.codeValid = result.isCodeValid;
        } else if (result.status !== 200) {
          this.codeValid = false;
          if (result.statusText === 'Forbidden') {
            this.$toast.open({ message: 'Código já expirou!', type: 'error' });
            setTimeout(() => {
              this.$router.push({ path: '/login' });
            }, 3000);
          }
        }
      }
    },
    methods: {
      async submitNewPass (e) {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        const submitResult = await userService.submitNewPass(this.user);
        if (this.submitResult === 'Timeout') {
          this.isLoading = false;
          this.$toast.open({ message: 'Timeout de conexão!', type: 'error' });
          return;
        }
        if (submitResult) {
          this.$toast.open('Senha alterada com sucesso!');
          setTimeout(() => {
            this.$router.push({ path: '/login' });
          }, 3000);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
.box_1 {
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: row;
        background: #ffff;
        height:100vh !important ;
        margin: 0;
        margin-left: 200px;
    }

.form_login {
    display: flex;
    flex-direction: column;
    background: #ffff;
    top: 30%;
}

.titleStyle {
        font-size: 28px;
        font-weight: bold;
        color: #153252;
        margin-left: 10px;
        margin-top: 10px;
    }

.invalid-feedback {
  margin-top: 0.1rem !important;
}

</style>
